import { webAssetUrl } from 'lib/utils/imageUtils';

const NotFoundPage = () => (
  <div className="relative bg-orange text-white text-xl sm:text-2xl overflow-hidden w-full h-full">
    <div className="flex flex-col h-full">
      <div className="max-w-screen-lg w-full m-auto px-6 py-6">
        <div className="text-center sm:text-left sm:w-2/3">
          <h1 className="text-3xl sm:text-4xl font-bold mb-4">
            ERROR &quot;303&quot;*
          </h1>
          <h2 className="mb-2">
            Oops. This page does not exist.
          </h2>
          <div className="mb-2">
            Still, we wouldn&apos;t blame you if you wanted to hang here for a bit.
            The serenity of nonexistence is unmatched.
          </div>
          <div className="mb-2 bg-orange bg-opacity-50">
            If you&apos;re here unexpectedly,
            you can check out our
            {' '}
            <a className="underline" href="https://status.gondola.cc">status page</a>
            {' '}
            for more information.
          </div>
          <div className="text-lg">
            *It&apos;s a Denver thing.
          </div>
        </div>
      </div>
      <img
        src={webAssetUrl('error-303.png')}
        alt="Error 303 illustration"
        className="w-full sm:w-1/2 sm:max-w-[700px] mt-auto sm:absolute sm:bottom-0 sm:right-0"
      />
    </div>
  </div>
);

export default NotFoundPage;
